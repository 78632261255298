/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "earaches-causes-and-remedies",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#earaches-causes-and-remedies",
    "aria-label": "earaches causes and remedies permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Earaches: Causes And Remedies"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hero-grey-glitter.png",
    alt: "Glitter Hero Background",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An earache can make life hard to enjoy. If left untreated, any sort of ear pain can lead to more serious health problems. That is why it is important to address any changes in your hearing health as soon as possible. On this page, you will read about some of the causes and symptoms associated with this common ailment. Additionally, you will learn more about how ear pain is diagnosed, treated, and prevented. The information included is especially important if you are older than 55 and your symptoms include impaired hearing."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-an-earache",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-an-earache",
    "aria-label": "what causes an earache permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Causes An Earache?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The way an earache feels for one person may not be the same for someone else. This is because people experience ear pain in a variety of different ways. Similarly, the cause of an earache varies depending on a number of factors. For instance, the pain someone experiences may be due to a problem that originates in one ear or both. In other cases, an earache is a result of other underlying health issues that affect the ears. Some common causes of earaches include:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Ear infection:"), " Infections can happen inside the ear (otitis interna) or ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-externa",
    className: "c-md-a"
  }, "outside the ear (otitis externa)")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2. Blocked ear canal:"), " A blocked ear canal could be a sign of ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax",
    className: "c-md-a"
  }, "earwax that has either hardened or gone too far into the canal.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "3. A change in ear pressure:"), " This can occur when flying on a plane, during the descent or ascent. You may also encounter this when diving deep in a body of water. This ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/pressure",
    className: "c-md-a"
  }, "ear pressure is called barotrauma.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "4. Strep throat")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "5. Sinus infection")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "6. A foreign object trapped in the ear")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "7. Glue ear:"), " This condition is also called ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-media",
    className: "c-md-a"
  }, "otitis media with effusion (OME)."), " “Glue ear” is characterized as built-up fluid that is deep inside the ear canal. Hearing loss may be present if a person has glue ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "8. Ear injury:"), " An injury to a person’s eardrum or ear canal can happen when a q-tip or any other object is used to remove ear wax or clean the canal. To avoid damaging the inner ear, one should avoid inserting any objects into the ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "9. Pain in the jaw")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This list is by no means exhaustive, but it should provide a general overview of the common causes typically associated with earaches. If you are experiencing ear pain, then there is a good chance that one of the causes noted above may be to blame."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are experiencing any sort of pain or discomfort in your ears, the next step is to identify the symptoms. Below you can check if any of these signs describe your current situation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-treat-an-earache",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-treat-an-earache",
    "aria-label": "how to treat an earache permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Treat an Earache"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Not all earaches require serious medical attention. In most cases, earaches, though uncomfortable and inconvenient, can go away on their own without the need for any special care or medicine. Nonetheless, when you understand the causes and symptoms, you will be better equipped to handle ear pain and choose the right treatment. Thankfully, there are several earache remedies that can help alleviate the symptoms. These remedies include:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "over-the-counter-medicine",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#over-the-counter-medicine",
    "aria-label": "over the counter medicine permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Over-the-counter medicine"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many pain relievers that you can find in your local grocery store or drugstore provide effective earache relief. Before spending money on seeing a doctor and buying expensive antibiotics, the best thing you can do is go to a pharmacy and pick up some ibuprofen or aspirin."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "a-warm-towel-or-ice-pack",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-warm-towel-or-ice-pack",
    "aria-label": "a warm towel or ice pack permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A warm towel or ice pack"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What seems to work for many people with ear pain is a warm towel or ice pack. Place either the towel or ice pack on the affected ear. After 10 minutes, move it to the other ear if pain is present in both."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "ear-drops",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ear-drops",
    "aria-label": "ear drops permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ear drops"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear drops for pain is a good treatment option. Antipyrine and benzocaine otic provide ear pain relief and can be found in most local drugstores. There are ear drops made from herbal extracts that may be more suitable for certain people. However, if you decide to use ear drops, you should speak to your primary care physician first to see if it’s safe for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-sleep-with-an-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-sleep-with-an-ear-infection",
    "aria-label": "how to sleep with an ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to sleep with an ear infection"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Certain sleeping positions can either make earache symptoms worse or better. If you are experiencing an ear pain, you should not sleep on the side where you have the pain. Instead, try to sleep with the affected ear raised or elevated – these two positions should reduce the pain and not aggravate your ear infection any further."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "chewing-gum",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#chewing-gum",
    "aria-label": "chewing gum permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Chewing gum"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is probably the most practical solution, especially if the ear pain is related to pressure caused by being on a plane."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "What about a home remedy for ear pain relief?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Home remedies are quick and convenient but should not replace the advice from your own doctor. When something seems to feel different in your physical health – whether it’s your ears or any other part of your body – it’s important you make sure there is nothing seriously wrong. If you don’t see any improvements after trying certain home remedies for an earache, you should speak to your doctor about your symptoms. In most cases, an earache is a temporary issue that can go away with a little bit of care. However, if the symptoms get worse or you don’t see improvements after several days, you should definitely reach out to a healthcare professional."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-prevent-earaches",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-prevent-earaches",
    "aria-label": "how to prevent earaches permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Prevent Earaches"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several practical things you can do to prevent earaches or ear infections. The first is to never poke the inside of your ear with an object like a q-tip. If an earache is a result of the flu, a flu shot could help with prevention. You can prevent pressure earaches by chewing gum before you board a plane and when the plane is about to descend."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best preventative measure you can take is to see your doctor once a year for your annual check up. Make sure you mention to your doctor if your are experiencing any pain in your ears that does not go away. The sooner you identify the problem, the sooner you will get the help you need."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
